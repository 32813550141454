<template>
    <div class="page formPage" id="opinionList">
        <el-row :gutter="12">
            <el-col :span="4">
                <el-input
                        size="small"
                        clearable
                        placeholder="手机号码/QQ号/电子邮件"
                        v-model="contactInformation"></el-input>
            </el-col>
            <el-col :span="4">
                <el-select size="small" style="width: 100%" clearable v-model="messageTypeId" placeholder="请选择留言类型">
                    <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="6">
                <el-date-picker
                        style="width: 100%"
                        size="small"
                        clearable
                        v-model="dataTime"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="提交开始日期"
                        end-placeholder="提交结束日期"
                        @change="changeDate">>
                </el-date-picker>
            </el-col>
            <el-col :span="6">
                <el-button size="small" type="primary" @click="getList(1)">查询</el-button>
                <el-button size="small" @click="reseat()">重置</el-button>
            </el-col>
        </el-row>
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                height="calc(100% - 90px)"
                class="table">
            <el-table-column prop="messageTypeId" label="分类" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.messageTypeId == 1 ? '投诉请求' : scope.row.messageTypeId == 2 ? '意见建议' : '其它'}}
                </template>
            </el-table-column>
            <el-table-column prop="messageValue" label="反馈内容" show-overflow-tooltip>
            </el-table-column>
            <!--                <el-table-column type="selection" width="50" fixed></el-table-column>-->
            <el-table-column prop="contactInformation" label="联系方式" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="createTime" label="提交时间" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{ scope.row.createTime | formatDate }}
                </template>
            </el-table-column>
            <!--            <el-table-column prop="mail" label="电子邮件" show-overflow-tooltip>-->
            <!--            </el-table-column>-->
            <!--            <el-table-column prop="phone" label="电话号码" show-overflow-tooltip>-->
            <!--            </el-table-column>-->
            <!--            <el-table-column prop="qq" label="QQ号" show-overflow-tooltip>-->
            <!--            </el-table-column>-->
            <el-table-column fixed="right" width="200" label="操作">
                <template slot-scope="scope">
                    <el-button
                            v-if="hasPermission('opinionList:opinionList:see')"
                            icon="el-icon-view" type="text"
                            size="mini" @click="details(scope.row.id,0)">详情
                    </el-button>
                    <!--                    <el-button-->
                    <!--                            v-if="hasPermission('opinionList:opinionList:delete')"-->
                    <!--                            icon="el-icon-delete" type="text"-->
                    <!--                            size="mini" @click="Wudelete(scope.row.id)">删除-->
                    <!--                    </el-button>-->
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                :current-page="pageNo"
                :page-size="pageSize"
                :page-sizes="[10, 20, 50, 100]"
                :total="total"
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                background
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!--详情编辑弹窗-->
        <el-dialog
                title="意见详情"
                :visible.sync="dialogVisible"
                width="600px"
                :close-on-click-modal="false"
                :before-close="handleClose">
            <el-form :model="ruleForm" ref="ruleForm" size="small" label-width="100px" class="aibdfuyavbdfyua">
                <el-form-item label="分类" prop="messageTypeId">
                    <!--                    <el-select style="width: 100%" :disabled="!distinguish" v-model="ruleForm.messageTypeId" placeholder="请选择留言类型">-->
                    <!--                        <el-option-->
                    <!--                                v-for="item in options"-->
                    <!--                                :key="item.value"-->
                    <!--                                :label="item.label"-->
                    <!--                                :value="item.value">-->
                    <!--                        </el-option>-->
                    <!--                    </el-select>-->
                    <div>{{ruleForm.messageTypeId == 1 ? '投诉请求' : ruleForm.messageTypeId == 2 ? '意见建议' : '其它'}}</div>
                </el-form-item>
                <el-form-item label="反馈内容" prop="messageValue">
                    <!--                    <el-input-->
                    <!--                            :disabled="!distinguish"-->
                    <!--                            type="textarea"-->
                    <!--                            :rows="4"-->
                    <!--                            placeholder="请输入内容"-->
                    <!--                            v-model="ruleForm.messageValue">-->
                    <!--                    </el-input>-->
                    <div>{{ ruleForm.messageValue }}</div>
                </el-form-item>
                <el-form-item label="联系方式" prop="contactInformation">
                    <!--                    <el-input :disabled="!distinguish" v-model="ruleForm.contactInformation"></el-input>-->
                    <div>{{ ruleForm.contactInformation }}</div>
                </el-form-item>
                <el-form-item label="提交时间" prop="createTime">
                    <!--                        <el-date-picker-->
                    <!--                                style="width: 100%"-->
                    <!--                                :disabled="!distinguish"-->
                    <!--                                v-model="ruleForm.createTime"-->
                    <!--                                type="datetime"-->
                    <!--                                placeholder="选择日期时间">-->
                    <!--                        </el-date-picker>-->
                    <div>{{ ruleForm.createTime }}</div>
                </el-form-item>
                <div style="width: 100%; height: 50px">&nbsp;</div>
                <!--                <el-form-item label="创建用户" prop="createUser">-->
                <!--                    <el-input :disabled="!distinguish" v-model="ruleForm.createUser"></el-input>-->
                <!--                </el-form-item>-->
                <!--                <el-form-item label="电子邮件" prop="mail">-->
                <!--                    <el-input :disabled="!distinguish" v-model="ruleForm.mail"></el-input>-->
                <!--                </el-form-item>-->
                <!--                <el-form-item label="电话号码" prop="phone">-->
                <!--                    <el-input :disabled="!distinguish" v-model="ruleForm.phone"></el-input>-->
                <!--                </el-form-item>-->
                <!--                <el-form-item label="QQ号" prop="qq">-->
                <!--                    <el-input :disabled="!distinguish" v-model="ruleForm.qq"></el-input>-->
                <!--                </el-form-item>-->
                <div style="text-align: center;position: absolute;bottom: 20px; width: 560px;background: #ffffff;box-sizing: border-box;padding-top: 10px">
                    <el-button @click="handleClose()">关闭</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "opinionList",
        data() {
            return {
                contactInformation: '',
                messageTypeId: '',//留言类型
                dataTime: [],
                createStartTime: '',
                createEndTime: '',
                options: [
                    {
                        value: '1',
                        label: '投诉请求'
                    },
                    {
                        value: '2',
                        label: '意见建议'
                    },
                    {
                        value: '3',
                        label: '其它'
                    },
                ],
                dataList: [],
                loading: false,
                pageNo: 1,
                pageSize: 10,
                total: 0,


                distinguish: false,
                dialogVisible: false,
                ruleForm: {
                    createTime: '',
                    createUser: '',
                    messageTypeId: '',
                    // mail: '',
                    // phone: '',
                    // qq: '',
                    contactInformation: '',
                    messageValue: '',
                },
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            //获取列表
            getList(num) {
                if (num){
                    this.pageNo = 1;
                }
                this.loading = true;
                this.$axios(this.api.feedback.list, {
                    messageTypeId: this.messageTypeId,
                    current: this.pageNo,
                    size: this.pageSize,
                    contactInformation: this.contactInformation,
                    createStartTime: this.createStartTime,
                    createEndTime: this.createEndTime,
                }, 'post').then(res => {
                    this.loading = false;
                    this.dataList = res.data.records;
                    this.total = parseInt(res.data.total)
                })
            },

            reseat() {
                this.contactInformation = '';
                this.messageTypeId = '';//留言类型
                this.dataTime = [];
                this.createStartTime = '';
                this.createEndTime = '';
                this.pageNo = 1
                this.getList(1)
            },

            details(id) {
                //获取文件详情
                this.$axios(this.api.feedback.getById + id, {}, 'get').then(res => {
                    this.ruleForm = res.data;
                })
                this.dialogVisible = true;
            },

            //删除
            Wudelete(id) {
                this.$confirm('此操作将永久删除该意见, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.feedback.removeById + id, {}, 'delete').then(res => {
                        console.log(res)
                        if (res.status) {
                            this.$message.success('删除成功');
                            if (this.dataList.length == 1 && this.pageNo > 1) this.pageNo--;
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                }).catch(() => {
                });
            },

            //更改时间
            changeDate(data) {
                if (data) {
                    this.createStartTime = data[0];
                    this.createEndTime = data[1];
                } else {
                    this.createStartTime = '';
                    this.createEndTime = '';
                }
            },

            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.getList();
            },

            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.getList();
            },

            //关闭弹窗
            handleClose() {
                this.dialogVisible = false;
                this.$refs['ruleForm'].resetFields();
            },
        }
    }
</script>

<style>
    .el-tooltip__popper {
        max-width: 60% !important;
    }

    .aibdfuyavbdfyua {
        height: 400px;
        overflow-y: auto;
    }
</style>
